<template>
  <div>
    <form id="signup-form" @submit.prevent="submit" class="column-row-group -mx-10">
      <div class="column-row py-10 md:w-6/12">
        <label for="form-first-name" class="footer-signup__label">First Name</label>
        <input
          id="form-first-name"
          type="text"
          name="first_name"
          required
          class="footer-signup__input"
          v-model.trim="form.first_name"
        />
      </div>
      <div class="column-row py-10 md:w-6/12">
        <label for="form-last-name" class="footer-signup__label">Last Name</label>
        <input
          id="form-last-name"
          type="text"
          name="last_name"
          required
          class="footer-signup__input"
          v-model.trim="form.last_name"
        />
      </div>
      <div class="column-row py-10 md:w-8/12">
        <label for="form-email" class="footer-signup__label">Email</label>
        <input
          id="form-email"
          type="email"
          name="email"
          required
          class="footer-signup__input"
          v-model.trim="form.email"
        />
      </div>
      <div class="column-row py-10 md:w-4/12">
        <label for="form-state" class="footer-signup__label">State</label>
        <div class="relative">
          <select
            id="form-state"
            name="state"
            required
            class="footer-signup__select"
            v-model="form.state"
          >
            <option value="">Please select...</option>
            <option value="ACT">ACT</option>
            <option value="NSW">NSW</option>
            <option value="NT">NT</option>
            <option value="QLD">QLD</option>
            <option value="SA">SA</option>
            <option value="TAS">TAS</option>
            <option value="VIC">VIC</option>
            <option value="WA">WA</option>
          </select>
          <IconArrow class="absolute select-arrow mr-16" />
        </div>
      </div>
      <div class="column-row py-20 md:w-9/12">
        <input type="checkbox" id="form-terms" name="consent" required v-model="form.consent" />
        <label for="form-terms" class="font-walsheim text-15">
          By submitting this form, I agree to ADF's
          <a href="https://adf.org.au/disclaimer/" class="text-link--light">Privacy Policy</a>
          .
        </label>
      </div>
      <div class="column-row py-10 md:w-3/12">
        <ButtonRound colour="lime" size="xlarge" text="Subscribe" type="submit" class="w-full" />
        <p v-if="success" class="text-white mt-10">
          {{ message }}
        </p>
        <p v-else-if="errors.global">{{ errors.global }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';
import IconArrow from '@/components/svg/IconArrow.vue';
import axios from 'axios';

export default {
  name: 'FooterSignupForm',
  components: { ButtonRound, IconArrow },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        state: '',
        states: ['ACT', 'NSW', 'NT', 'QLD', 'TAS', 'SA', 'VIC', 'WA'],
        consent: false
      },
      errors: {
        first_name: '',
        last_name: '',
        email: '',
        state: '',
        consent: '',
        global: ''
      },
      message: '',
      success: false
    };
  },
  methods: {
    submit() {
      axios
        .post('/subscribe/', this.form)
        .then(response => {
          this.success = true;
          this.message = response.data.message;
          document.getElementById('signup-form').scrollIntoView({
            behavior: 'smooth'
          });
        })
        .catch(error => {
          // If it's an error from
          if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'title')) {
            this.$set(this.errors, 'global', error.data.errors.title);
          } else {
            // If it's an error from the server
            Object.keys(error.response.data.errors).forEach(key => {
              this.$set(this.errors, key, error.data.errors[key][0].message);
            });
          }
          console.log('fail', error);
        });
    },
    /**
     * Clear an error
     *
     * @param {String} key of error to clear
     */
    clearError(error) {
      this.$set(this.errors, error, '');
    }
  }
};
</script>

<style>
.footer-signup__label {
  @apply font-walsheim font-bold mb-2;
}

.footer-signup__select,
.footer-signup__input {
  @apply px-24 py-12 w-full rounded-24 text-black;
}

.footer-signup__select {
  @apply bg-white pr-30;
  appearance: none;
}
</style>
